<template>
  <div class="base-menu-reservation-new-button">
    <button
      class="base-menu-reservation-new-button-class"
      data-e2e="base-menu-reservation-new-button"
      type="button"
      @click="handleClick"
    >
      <div class="wrapper">
        <img
          src="@/assets/base_menu_reservation_new_button.png"
          class="base-menu-reservation-new-button-img"
          width="51x"
          height="51px"
          alt="base-menu-reservation-new-button"
        />
        <span class="base-menu-reservation-new-button-text">{{
          $t('parts.menuReservationNew')
        }}</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseMenuReservationNewButton',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-menu-reservation-new-button {
  > .base-menu-reservation-new-button-class {
    height: 130px;
    border-radius: 20px;
    border: solid 4px #{$plus_orange};
    background-color: #{$plus_orange};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 128px;
    }
    > .wrapper {
      > .base-menu-reservation-new-button-img {
        vertical-align: middle;
      }
      > .base-menu-reservation-new-button-text {
        font-weight: bold;
        line-height: 1.45;
        color: #{$plus_white};
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
  @include selector-width('.base-menu-reservation-new-button-class', 335px);
  @include selector-height('.wrapper', 130px);
  $button-width: 335px;
  $wrapper-height: 130px;
  $img-width: 51px;
  $img-height: 51px;
  $text-margin-left: 30px;
  @media (min-width: 640px) {
    > .base-menu-reservation-new-button-class {
      > .wrapper {
        > .base-menu-reservation-new-button-img {
          width: $img-width;
        }
        > .base-menu-reservation-new-button-text {
          margin-left: $text-margin-left;
          @include font-size(20);
        }
      }
    }
  }
  @media (max-width: 640px) {
    > .base-menu-reservation-new-button-class {
      > .wrapper {
        > .base-menu-reservation-new-button-img {
          width: calc-percent($img-width, $button-width);
          height: calc-percent($img-height, $wrapper-height);
        }
        > .base-menu-reservation-new-button-text {
          margin-left: calc-percent($text-margin-left, $button-width);
          white-space: pre-line;
          @include font-size(20);
        }
      }
    }
  }
}
</style>
