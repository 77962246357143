<template>
  <div class="reservation-menu">
    <div class="page-title">
      <base-page-title>{{ $t('reservationMenu.pageTitle') }}</base-page-title>
    </div>
    <div class="owner-name">
      {{
        $t('common.ownerName', {
          first: owner.firstName,
          last: owner.lastName,
          honorific: '\u{3000}様'
        })
      }}
    </div>
    <div
      v-if="reservationSetting.netAvailableFlg && allowReservations"
      class="reservation-new-button"
    >
      <base-menu-reservation-new-button @click="pushToReservationPurpose" />
    </div>
    <div
      v-if="!reservationSetting.netAvailableFlg"
      class="net-unavailable-message"
      data-test="net-unavailable-message"
    >
      {{ $t('reservationMenu.netUnavailableMessage') }}<br />{{
        $t('reservationMenu.onlyCheckedMessage')
      }}
    </div>
    <div
      v-if="reservationSetting.netAvailableFlg && !allowReservations"
      class="net-unavailable-message"
      data-test="net-unavailable-message"
    >
      {{ $t('reservationMenu.reachedToMaxMessage') }}<br />{{
        $t('reservationMenu.onlyCheckedMessage')
      }}
    </div>
    <div class="check-reservation-button">
      <base-check-reservation-button
        @click="pushToReservationConfirm"
        :disabled="waitFlg"
      />
    </div>
    <div class="check-information-button">
      <base-check-information-button @click="pushToOwnerPatientsInformation" />
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="$t('common.error')"
      :buttons="[$t('common.close')]"
      :type="'failure'"
      >{{ $t('reservationMenu.popupMessage') }}</announce-popup
    >
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseMenuReservationNewButton from '@/components/parts/atoms/BaseMenuReservationNewButton.vue'
import BaseCheckReservationButton from '@/components/parts/atoms/BaseCheckReservationButton.vue'
import BaseCheckInformationButton from '@/components/parts/atoms/BaseCheckInformationButton.vue'
import { getAxiosObject } from '@/utils/library'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import PreventBack from '@/components/mixins/PreventBack'

export default {
  name: 'ReservationMenu',

  components: {
    BasePageTitle,
    BaseMenuReservationNewButton,
    BaseCheckReservationButton,
    BaseCheckInformationButton,
    AnnouncePopup
  },

  mixins: [PreventBack],

  data() {
    return {
      alertFlg: false,
      waitFlg: false
    }
  },

  computed: {
    owner() {
      return this.$store.getters['owner/getOwner']
    },
    reservationSetting() {
      return this.$store.getters['reservationSetting/getReservationSetting']
    },
    netReservations() {
      return this.$store.getters['reservation/getReservations'].filter(
        r => r.netFlg === 1
      )
    },
    allowReservations() {
      const cap = this.reservationSetting.ownerReservationCap
      if (cap === 0) return true
      else {
        const reservationLength = this.countReservations(this.netReservations)
        const reservationRequestLength = this.countReservations(
          this.$store.getters['reservationRequests/getData']
        )
        return reservationLength + reservationRequestLength < cap
      }
    }
  },

  mounted() {
    this.scenarioReset()
  },

  methods: {
    scenarioReset() {
      this.$store.dispatch('auth/scenarioReset')
      this.$store.dispatch('patient/resetNewPatients')
      this.$store.dispatch('reservation/resetNewReservation')
    },
    pushToReservationPurpose() {
      this.$store.dispatch('auth/newReservationScenario')
      this.$router.push({ name: 'ReservationPurpose' })
    },
    async pushToReservationConfirm() {
      this.waitFlg = true
      try {
        const axiosObject = getAxiosObject()
        const res = await axiosObject.get('/reservations')
        this.$store.dispatch(
          'reservation/setReservations',
          res.data.reservations
        )
        this.$store.dispatch(
          'reservationRequests/setData',
          res.data.reservationRequests
        )
        this.$store.dispatch(
          'reservationPurpose/addReservationPurposes',
          res.data.reservationPurposes
        )
        this.$store.dispatch('patient/addPatients', res.data.patients)
        this.$router.push({ name: 'ReservationConfirm' })
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
      }
    },
    countReservations(reservations) {
      let reservationsNoKey = 0
      let keys = {}
      reservations.forEach(r => {
        if (r.groupKey === '') reservationsNoKey++
        else if (keys[r.groupKey]) keys[r.groupKey]++
        else keys[r.groupKey] = 1
      })
      return reservationsNoKey + Object.keys(keys).length
    },
    pushToOwnerPatientsInformation() {
      this.$store.dispatch('auth/changeOwnerPatientsScenario')
      this.$router.push({ name: 'OwnerPatientsInformation' })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-menu {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 70px;
  }
  > .owner-name {
    height: 27px;
    font-weight: bold;
    margin-top: 105px;
  }
  > .net-unavailable-message {
    margin-top: 50px;
    font-weight: bold;
    @include font-size(18);
  }
  > .reservation-new-button {
    margin-top: 80px;
  }
  > .check-reservation-button,
  .check-information-button {
    margin-top: 50px;
  }
}
@media (max-width: $global-container) {
  .reservation-menu {
    > .net-unavailable-message {
      max-width: 550px;
      width: calc-percent(550px, $global-container);
      margin: 50px auto 0;
    }
  }
}
</style>
