<template>
  <div class="base-check-reservation-button">
    <button
      class="check-reservation-button"
      type="button"
      @click="handleClick"
      :disabled="disabled"
    >
      <div class="wrapper">
        <img
          src="@/assets/base_check_reservation_button.png"
          class="check-reservation-button-img"
          width="49px"
          height="49px"
          alt="check-reservation-button"
        />
        <span class="check-reservation-button-text">{{
          $t('parts.menuCheckReservation')
        }}</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckReservationButton',

  props: {
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-check-reservation-button {
  > .check-reservation-button {
    height: 130px;
    border-radius: 20px;
    border: solid 4px #{$plus_light_orange};
    background-color: #{$plus_light_orange};
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 128px;
    }
    > .wrapper {
      > .check-reservation-button-img {
        display: inline-block;
        vertical-align: middle;
      }
      > .check-reservation-button-text {
        font-weight: bold;
        line-height: 1.45;
        color: #{$plus_white};
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  @include selector-width('.check-reservation-button', 335px);
  @include selector-height('.wrapper', 130px);
  $button-width: 335px;
  $wrapper-height: 130px;
  $img-width: 49px;
  $img-height: 49px;
  $text-margin-left: 30px;
  @media (min-width: 640px) {
    > .check-reservation-button {
      > .wrapper {
        > .check-reservation-button-img {
          width: $img-width;
        }
        > .check-reservation-button-text {
          margin-left: $text-margin-left;
          @include font-size(20);
        }
      }
    }
  }
  @media (max-width: 640px) {
    > .check-reservation-button {
      > .wrapper {
        > .check-reservation-button-img {
          width: calc-percent($img-width, $button-width);
          height: calc-percent($img-height, $wrapper-height);
        }
        > .check-reservation-button-text {
          margin-left: calc-percent($text-margin-left, $button-width);
          @include font-size(20);
          white-space: pre-line;
        }
      }
    }
  }
}
</style>
