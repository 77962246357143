<template>
  <div class="base-check-information-button">
    <button class="check-information-button" type="button" @click="handleClick">
      <div class="wrapper">
        <img
          src="@/assets/base_check_information_button.png"
          class="check-information-button-img"
          width="52px"
          height="55px"
          alt="check-information-button"
        />
        <span class="check-information-button-text"
          >{{ $t('parts.menuCheckInformation1') }}<br />{{
            $t('parts.menuCheckInformation2')
          }}</span
        >
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckInformationButton',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-check-information-button {
  > .check-information-button {
    height: 130px;
    border-radius: 20px;
    border: solid 4px #{$plus_light_orange};
    background-color: #{$plus_light_orange};
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 128px;
    }
    > .wrapper {
      > .check-information-button-img {
        display: inline-block;
        vertical-align: middle;
      }
      > .check-information-button-text {
        font-weight: bold;
        line-height: 1.45;
        color: #{$plus_white};
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  @include selector-width('.check-information-button', 335px);
  @include selector-height('.wrapper', 130px);
  $button-width: 335px;
  $wrapper-height: 130px;
  $img-width: 52px;
  $img-height: 55px;
  $text-margin-left: 19.5px;
  @media (min-width: 640px) {
    > .check-information-button {
      > .wrapper {
        > .check-information-button-img {
          width: $img-width;
        }
        > .check-information-button-text {
          margin-left: $text-margin-left;
          @include font-size(20);
        }
      }
    }
  }
  @media (max-width: 640px) {
    .check-information-button {
      > .wrapper {
        > .check-information-button-img {
          width: calc-percent($img-width, $button-width);
          height: calc-percent($img-height, $wrapper-height);
        }
        > .check-information-button-text {
          margin-left: calc-percent($text-margin-left, $button-width);
          @include font-size(20);
        }
      }
    }
  }
}
</style>
